.card {
  border-radius: 25px;
  position: relative;
  padding: 25px 15px;
  background-color: #ffffff;
  margin: 50px auto;
  height: auto;
  width: auto;
  box-shadow: 0 2px 5px #ccc;
  text-align: left;
  overflow: hidden;
}
.top {
  border-radius: 25px 25px 0 0;
  height: 100px;
  width: 100%;
  background-color: #34a8cf;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  box-sizing: border-box;
}
.nameContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: flex-start;
  margin-top: 20px;
  margin-left: 25px;
  padding-top: 20px;
}
.name {
  font-size: 2em;
  color: #000;
  margin: 0;
}
.cargo {
  font-size: 1.3em;
  color: #000;
  margin: 0;
}
.circleImg {
  border-radius: 50%;
  width: 160px;
  height: 160px;
  margin-top: 90px;
  margin-left: 25px;
}
.bottom {
  margin-top: 90px;
  position: relative;
}
.colegio_eleitoral {
  margin-top: 1;
  color: #000;
}
.atualizacao {
  display: flex;
  justify-content: flex-end;
  color: #000;
  text-align: right;
}
.btn {
  display: flex;
  justify-content: flex-end;
}
.button {
  cursor: pointer;
  margin: 0 0 20px auto;
  display: flex;
  justify-content: flex-end;
  border: 2px solid #00bcd4;
  border-radius: 4px;
  padding: 6px 12px;
  background-color: #00bcd4;
  color: #fff;
}

.cargoContainer {
  font-size: 1rem;
}
