.cardArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 250px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.titleArea {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  color: black;
  font-weight: bold;
}

.captionArea {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 10px;
  color: gray;
}

.archiveArea {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px dashed grey;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  cursor: pointer;
  border: none;
  padding: 0;
  transition: transform 0.2s;
}

.button:hover {
  transform: scale(1.1);
}

.dragOver {
  border: 2px dashed #007bff;
}

.fileUploadArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.archiveName {
  display: flex;
  width: auto;
  justify-content: center;
  margin-top: 5px;
  color: gray;
  text-transform: none;
  font-size: 0.7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.detailArea {
  margin-right: auto;
  display: flex;
  font-size: 0.7rem;
  color: gray;
}

.buttonArea {
  display: flex;
  margin-left: auto;
  padding: 0;
}
