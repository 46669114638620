.chart {
  background-color: #fefefe;
  margin: auto;
  height: 300px;
  width: 100%;
  position: relative;
  border-radius: 5px;
}

.legendWrapper {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 10;
}
